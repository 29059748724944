<template>
  <v-container>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            :interval-count="0"
            :interval-height="0"
            @click:event="showEvent"
            @click:more="viewDay"
            @change="getEvents"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="300px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <span v-html="`${selectedEvent.detail}`"></span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  color="secondary"
                  class="ma-2"
                  outlined
                  :to="'/customer/' + selectedEvent.customerId + '/show'"
                >
                  <v-icon>
                    mdi-magnify
                  </v-icon>
                  Detail
                </v-btn>
                <v-btn
                  small
                  outlined
                  class="ma-2"
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import axios from "axios";
// import moment from "moment";
export default {
  data: () => ({
    loading: false,
    focus: "",
    type: "month",
    selectedEvent: {
      // customerUseProduct: {
      //   productCategory: {},
      //   customer: {},
      // },
      // customerUseService: {
      //   serviceCategory: {},
      //   customer: {},
      // },
    },
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "red",
      "yellow",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    getEventColor(event) {
      return event.color;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async getEvents() {
    // async getEvents({ start, end }) {
    //   const events = [];
    //   var customerUseProducts = [];
    //   var customerUseServices = [];

    //   const min = new Date(`${start.date}`);
    //   const max = new Date(`${end.date}`);

    //   const queryParams = {
    //     params: {
    //       startDate: min,
    //       endDate: max,
    //     },
    //   };
    //   this.loading = true;

    //   const customerUseProductPromise = axios
    //     .get("/customer-use-product/expire-date-in-range/", queryParams)
    //     .then(async (response) => {
    //       // JSON responses are automatically parsed.
    //       customerUseProducts = response.data;
    //       Promise.all(
    //         customerUseProducts.map((customerUseProduct) => {
    //           events.push({
    //             name: customerUseProduct.customerUseService.customer.name,
    //             customerId: customerUseProduct.customerUseService.customer.id,
    //             detail: `ผลิตภัณฑ์ ${customerUseProduct.productCategory.name} ${customerUseProduct.code === null ? '' : '('+customerUseProduct.code+')'} สิ้นสุดสัญญา`,
    //             start: customerUseProduct.expireDate,
    //             end: customerUseProduct.expireDate,
    //             color: "error",
    //           });
    //         })
    //       );
    //       this.events = events;
    //       // console.log(this.events);
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });

    //   const customerUseServicePromise = axios
    //     .get("/customer-use-service/expire-date-in-range/", queryParams)
    //     .then(async (response) => {
    //       // JSON responses are automatically parsed.
    //       customerUseServices = response.data;
    //       Promise.all(
    //         customerUseServices.map((customerUseService) => {
    //           events.push({
    //             name: customerUseService.customer.name,
    //             customerId: customerUseService.customer.id,
    //             detail: `บริการ ${customerUseService.serviceCategory.name} ${customerUseService.code === null ? '' : '('+customerUseService.code+')'} สิ้นสุดสัญญา`,
    //             start: customerUseService.expireDate,
    //             end: customerUseService.expireDate,
    //             color: "error",
    //           });
    //         })
    //       );
    //       this.events = events;
    //       // console.log(this.events);
    //     })
    //     .catch(() => {});

    //   Promise.all([customerUseProductPromise, customerUseServicePromise])
    //     .then((this.loading = false))
    //     .catch((this.loading = false));
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        // console.log(event)
        this.selectedEvent = event;
        // console.log(this.selectedEvent)
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>
